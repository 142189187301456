@media (prefers-color-scheme: dark) {
  :root {
    --color-text: var(--color-grey-50);
    --color-text-secondary: var(--color-grey-200);
    --color-background: var(--color-grey-900);
    --color-background-inline-code: var(--color-info-950);
    --color-link-active: var(--color-grey-200);
    --color-link-hover: var(--color-grey-300);
  }

  .notice {
    &--info {
      --color-background-inline-code: var(--color-info-950);

      @include notice;
      background-color: var(--color-info-900);
      border-color: var(--color-info-100);
    }

    &--warning {
      --color-background-inline-code: var(--color-warning-900);

      @include notice;
      background-color: var(--color-warning-800);
      border-color: var(--color-warning-100);
    }

    &--error {
      --color-background-inline-code: var(--color-error-900);

      @include notice;
      background-color: var(--color-error-700);
      border-color: var(--color-error-100);
    }
  }
}

@media screen and (min-width: 50rem) {
  :root {
    --font-base-size: 1.125rem;
  }
}

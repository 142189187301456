div.highlighter-rouge,
figure.highlight {
  position: relative;
  margin-block: var(--space-lg);
  font-family: "Space Mono", monospace;
  font-size: var(--font-size-md);

  [dir="rtl"] & {
    direction: ltr;
    text-align: start;
  }

  > pre,
  pre.highlight {
    margin: 0;
    padding: var(--space-md);
  }
}

.highlight table {
  margin-bottom: 0;
  border: 0;

  td {
    padding: 0;
    width: calc(100% - 1em);
    border: 0;

    /* line numbers*/
    &.gutter,
    &.rouge-gutter {
      padding-inline-end: 1em;
      width: 1em;
      color: var(--color-grey-100);
      border-inline-end: 1px solid (--color-grey-100);
      text-align: end;
    }

    /* code */
    &.code,
    &.rouge-code {
      padding-inline-start: 1em;
    }
  }

  pre {
    margin: 0;
  }
}

.highlight {
  color: #faf6e4;
  background-color: #122b3b;
  border-radius: var(--border-radius-sm);
}
.highlight .gl {
  color: #dee5e7;
  background-color: #4e5d62;
}
.highlight .gp {
  color: #a8e1fe;
}
.highlight .c,
.highlight .ch,
.highlight .cd,
.highlight .cm,
.highlight .cpf,
.highlight .c1,
.highlight .cs {
  color: #6c8b9f;
  font-style: italic;
}
.highlight .cp {
  color: #b2fd6d;
}
.highlight .err {
  color: #fefeec;
  background-color: #cc0000;
}
.highlight .gr {
  color: #cc0000;
  font-style: italic;
}
.highlight .k,
.highlight .kd,
.highlight .kv {
  color: #f6dd62;
}
.highlight .o,
.highlight .ow {
  color: #4df4ff;
}
.highlight .p,
.highlight .pi {
  color: #4df4ff;
}
.highlight .gd {
  color: #cc0000;
}
.highlight .gi {
  color: #b2fd6d;
}
.highlight .ge {
  font-style: italic;
}
.highlight .gt {
  color: #dee5e7;
  background-color: #4e5d62;
}
.highlight .kc {
  color: #f696db;
}
.highlight .kn {
  color: #ffb000;
}
.highlight .kp {
  color: #ffb000;
}
.highlight .kr {
  color: #ffb000;
}
.highlight .gh {
  color: #ffb000;
}
.highlight .gu {
  color: #ffb000;
}
.highlight .kt {
  color: #b2fd6d;
}
.highlight .no {
  color: #b2fd6d;
}
.highlight .nc {
  color: #b2fd6d;
}
.highlight .nd {
  color: #b2fd6d;
}
.highlight .nn {
  color: #b2fd6d;
}
.highlight .bp {
  color: #b2fd6d;
}
.highlight .ne {
  color: #b2fd6d;
}
.highlight .nl {
  color: #ffb000;
}
.highlight .nt {
  color: #ffb000;
}
.highlight .m,
.highlight .mb,
.highlight .mf,
.highlight .mh,
.highlight .mi,
.highlight .il,
.highlight .mo,
.highlight .mx {
  color: #f696db;
}
.highlight .ld {
  color: #f696db;
}
.highlight .ss {
  color: #f696db;
}
.highlight .s,
.highlight .sb,
.highlight .dl,
.highlight .sd,
.highlight .s2,
.highlight .sh,
.highlight .sx,
.highlight .sr,
.highlight .s1 {
  color: #fff0a6;
}
.highlight .sa {
  color: #f6dd62;
}
.highlight .se {
  color: #4df4ff;
}
.highlight .sc {
  color: #4df4ff;
}
.highlight .si {
  color: #4df4ff;
}
.highlight .nb {
}
.highlight .ni {
  color: #999999;
}
.highlight .w {
  color: #bbbbbb;
}
.highlight .go {
  color: #bbbbbb;
}
.highlight .nf,
.highlight .fm {
  color: #a8e1fe;
}
.highlight .py {
  color: #a8e1fe;
}
.highlight .na {
  color: #a8e1fe;
}
.highlight .nv,
.highlight .vc,
.highlight .vg,
.highlight .vi,
.highlight .vm {
  color: #a8e1fe;
}

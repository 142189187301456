:root {
  --font-base-size: 1rem;
  --font-scale-ratio: 1.3;

  --color-grey-50: oklch(98.14% 0.001 286.38);
  --color-grey-100: oklch(91.34% 0.003 286.35);
  --color-grey-200: oklch(83.09% 0.006 286.28);
  --color-grey-300: oklch(73.99% 0.009 286.19);
  --color-grey-400: oklch(65.59% 0.012 286.07);
  --color-grey-500: oklch(55.86% 0.014 285.95);
  --color-grey-600: oklch(47.36% 0.011 285.97);
  --color-grey-700: oklch(37.33% 0.008 285.98);
  --color-grey-800: oklch(27.39% 0.005 286.03);
  --color-grey-900: oklch(15.95% 0.002 286.16);
  --color-grey-950: oklch(10.91% 0.003 286.03);

  --color-info-50: oklch(98.34% 0.02 200.65);
  --color-info-100: oklch(97.05% 0.035 200.94);
  --color-info-200: oklch(94.01% 0.07 201.54);
  --color-info-300: oklch(91.82% 0.098 201.16);
  --color-info-400: oklch(89.65% 0.123 201.63);
  --color-info-500: oklch(88.09% 0.137 202.5);
  --color-info-600: oklch(86.53% 0.148 204.02);
  --color-info-700: oklch(70.48% 0.12 203.91);
  --color-info-800: oklch(51.9% 0.088 204.04);
  --color-info-900: oklch(33.12% 0.056 202);
  --color-info-950: oklch(20.99% 0.036 201.16);

  --color-warning-50: oklch(97.76% 0.025 86.87);
  --color-warning-100: oklch(95.6% 0.049 86.35);
  --color-warning-200: oklch(91.46% 0.095 86);
  --color-warning-300: oklch(87.64% 0.134 84.75);
  --color-warning-400: oklch(83.99% 0.16 81.18);
  --color-warning-500: oklch(81.24% 0.17 76.39);
  --color-warning-600: oklch(68.48% 0.144 75.76);
  --color-warning-700: oklch(55.69% 0.116 77.27);
  --color-warning-800: oklch(41.91% 0.087 78.88);
  --color-warning-900: oklch(26.85% 0.055 82.97);
  --color-warning-950: oklch(18.29% 0.037 88.42);

  --color-error-50: oklch(93.22% 0.034 17.78);
  --color-error-100: oklch(86.89% 0.07 18.6);
  --color-error-200: oklch(75.29% 0.149 21.22);
  --color-error-300: oklch(66.66% 0.22 25.5);
  --color-error-400: oklch(62.99% 0.256 28.99);
  --color-error-500: oklch(53.08% 0.218 29.23);
  --color-error-600: oklch(44.93% 0.184 29.23);
  --color-error-700: oklch(36.39% 0.149 29.23);
  --color-error-800: oklch(27.54% 0.113 29.23);
  --color-error-900: oklch(17.64% 0.072 29.23);
  --color-error-950: oklch(12.01% 0.049 29.23);

  --color-text: var(--color-grey-900);
  --color-text-secondary: var(--color-grey-600);
  --color-background: var(--color-grey-50);
  --color-background-code: var(--color-info-950);
  --color-background-inline-code: var(--color-grey-100);
  --color-background-thead: var(--color-grey-100);
  --color-link-active: var(--color-grey-700);
  --color-link-hover: var(--color-grey-600);

  --font-size-sm: calc(var(--font-base-size) / var(--font-scale-ratio));
  --font-size-md: var(--font-base-size);
  --font-size-lg: calc(var(--font-size-md) * var(--font-scale-ratio));
  --font-size-xl: calc(var(--font-size-lg) * var(--font-scale-ratio));
  --font-size-2xl: calc(var(--font-size-xl) * var(--font-scale-ratio));
  --font-size-3xl: calc(var(--font-size-2xl) * var(--font-scale-ratio));
  --font-size-4xl: calc(var(--font-size-2xl) * var(--font-scale-ratio));

  --space-unit: 1rem;
  --space-xxs: calc(0.25 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1 * var(--space-unit));
  --space-lg: calc(1.5 * var(--space-unit));
  --space-xl: calc(2 * var(--space-unit));
  --space-2xl: calc(3.25 * var(--space-unit));
  --space-3xl: calc(5.25 * var(--space-unit));

  --border-radius-sm: 5px;
  --border-radius-md: 10px;
}

@font-face {
  font-family: "Rubik";
  src:
    local("Rubik"),
    url("/assets/fonts/Rubik.woff2") format("woff2 supports variations"),
    url("/assets/fonts/Rubik.woff2") format("woff2-variations");
  font-weight: 1 999;
  font-display: optional;
}

@font-face {
  font-family: "Space Mono";
  src:
    local("Space Mono"),
    url("/assets/fonts/SpaceMono.woff2") format("woff2 supports variations"),
    url("/assets/fonts/SpaceMono.woff2") format("woff2-variations");
  font-weight: 400;
  font-style: normal;
  font-display: optional;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: var(--color-background);
  color: var(--color-text);
  line-height: 1.7;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
}

main {
  margin: 0 auto;
  max-width: 50rem;
  padding: var(--space-sm) var(--space-md);
  font-size: var(--font-size-md);
  min-height: 80vh;
}

a {
  display: inline-block;
  color: var(--color-text);
  transition: color 200ms ease-in-out;

  &:hover {
    color: var(--color-link-hover);
  }

  &:active {
    color: var(--color-link-active);
  }
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
}

h1 {
  font-size: var(--font-size-2xl);
  font-weight: 500;
}

h2 {
  font-size: var(--font-size-xl);
  font-weight: 400;
}

h3 {
  font-size: var(--font-size-lg);
  font-weight: 400;
}

h4 {
  font-size: var(--font-size-md);
}

p {
  margin-bottom: var(--space-md);
}

pre {
  overflow-x: auto;
}

:not(pre) > code {
  padding: var(--space-xxs);
  background: var(--color-background-inline-code);
  border-radius: var(--border-radius-sm);
  font-family: "Space Mono", monospace;
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
  vertical-align: middle;
  border-radius: var(--border-radius-sm);
}

table {
  display: block;
  margin-bottom: var(--space-md);
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
}
table + table {
  margin-top: var(--space-md);
}

thead {
  background-color: var(--color-background-inline-code);
  color: var(--color-text);
  border-bottom: 2px solid var(--color-text-secondary);
}
th {
  padding: var(--space-sm);
  font-weight: bold;
  text-align: left;
}
td {
  padding: var(--space-sm);
  border-bottom: 1px solid var(--color-text-secondary);
}
tr,
td,
th {
  vertical-align: middle;
}

figure {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: var(--space-lg) 0;
}
figure img,
figure iframe {
  margin-bottom: var(--space-sm);
}

figure img {
  border-radius: var(--border-radius-sm);
  transition: none;
}

figure > a {
  display: block;
}

figcaption {
  color: var(--color-text-secondary);
  margin-bottom: var(--space-sm);
}

::selection {
  background: var(--color-text);
  color: var(--color-background);
}

:focus-visible {
  outline: 2px dashed var(--color-text-secondary);
  outline-offset: 2px;
}

.footer {
  margin: 0 auto;
  max-width: 50rem;
  margin-top: var(--space-3xl);

  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: var(--space-md);
  padding: var(--space-lg) var(--space-sm);

  &__copyright {
    margin: 0;
  }

  &__links {
    display: flex;
    gap: var(--space-md);
  }
}

p {
  line-height: 1.7;
}

.avatar {
  size: 8rem;
  max-width: 8rem;
  border-radius: var(--border-radius-md);
  margin: var(--space-sm) 0;
}

.title {
  margin-top: var(--space-md);
  margin-bottom: var(--space-lg);
  word-spacing: var(--space-xs);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--space-3xl);
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 50rem;
  margin: 0 auto;
  padding: var(--space-lg) var(--space-md);

  &__title {
    font-size: var(--font-size-lg);
    text-decoration: none;
  }

  &__links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--space-sm);

    a {
      text-decoration: none;
    }
  }
}

.work-items,
.posts {
  display: flex;
  flex-direction: column;
  gap: var(--space-2xl);
}

.work-item {
  display: flex;
  flex-direction: column;
  line-height: 1.5;

  &__title {
    font-size: var(--font-size-md);
    font-weight: 600;
    margin-bottom: var(--space-md);
  }

  &__description {
    margin-bottom: 0;
  }

  &__skills {
    display: flex;
    flex-direction: row;
    margin-block: 0;
    flex-wrap: wrap;
    column-gap: var(--space-xs);

    .skill {
      color: var(--color-text-secondary);
      display: inline-flex;
    }
  }
}

.post {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);

  &__title {
    font-size: var(--font-size-md);
    font-weight: 600;
    margin-bottom: var(--space-xs);
  }

  &__excerpt {
    margin-bottom: 0;
  }

  &__meta {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--space-xs);
    margin-block: 0;

    span {
      color: var(--color-text-secondary);
      line-height: 1;
      display: inline-flex;
    }
  }
}

.socials {
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: var(--space-xl);
  row-gap: var(--space-sm);

  p {
    margin-bottom: 0;
  }
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--space-2xl);

  ul {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
  }

  span.page {
    font-weight: 600;
  }

  a {
    .disabled {
      color: var(--color-grey-500);
      text-decoration: none;
    }
  }
}

.page {
  h2 {
    margin-top: var(--space-2xl);
    margin-bottom: var(--space-lg);
  }

  h3 {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-md);
  }

  ul,
  ol {
    padding-left: var(--space-lg);
    margin-bottom: var(--space-md);
    li {
      margin-bottom: var(--space-sm);
    }
  }

  .impressum {
  }

  &__meta {
    display: flex;
    gap: var(--space-md);
    margin-top: var(--space-md);
    margin-bottom: var(--space-2xl);
    color: var(--color-text-secondary);
  }
}

.mt-2xl {
  margin-top: var(--space-2xl);
}
.mb-md {
  margin-bottom: var(--space-md);
}

.mb-lg {
  margin-bottom: var(--space-lg);
}

.mb-xl {
  margin-bottom: var(--space-xl);
}

.mb-2xl {
  margin-bottom: var(--space-2xl);
}

@mixin notice {
  border-radius: var(--border-radius-sm);
  padding: var(--space-md) var(--space-sm);
  border: 1px solid var(--color-grey-50);
}

.notice {
  &--info {
    --color-background-inline-code: var(--color-info-100);

    @include notice;
    background-color: var(--color-info-50);
    border-color: var(--color-info-800);
  }

  &--warning {
    --color-background-inline-code: var(--color-warning-200);

    @include notice;
    background-color: var(--color-warning-50);
    border-color: var(--color-warning-600);
  }

  &--error {
    --color-background-inline-code: var(--color-error-100);

    @include notice;
    background-color: var(--color-error-50);
    border-color: var(--color-error-600);
  }
}

.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--space-md);
  padding-top: var(--space-3xl);
}
